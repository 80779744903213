








































































































































































































import { CommonRulesFields } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '@/mixins/input-setups'
import chatConfig from '@/mixins/chatConfig'
import ExtraSettingsBackButton from '@/views/chat/filters/components/ExtraSettingsBackButton.vue'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Placeholders from '@/mixins/placeholders/placeholders'

@Component({
  components: {
    ExtraSettingsBackButton,
  },
  data() {
    return {
      MediaEditorMode,
      CommonRulesFields,
    }
  },
  mixins: [ chatConfig ]
})
export default class CommonRulesSettings extends Mixins<UseFields, InputSetups, FilterFieldsMixins, Placeholders>(UseFields, InputSetups, FilterFieldsMixins, Placeholders) {

  @Prop({ type: Object }) editorProps!: {
    [CommonRulesFields.Warning_message]: {
      placeholders: any
    } | undefined,
    [CommonRulesFields.Ban_message]: {
      placeholders: any
    } | undefined
  } | undefined

  muteMediaFilters: Array<string> = [ 'sticker', 'voice', 'gif', 'sticker_pack', 'audio', 'video', 'video_note' ]

  get warnMessageEditorProps():any {
    if (this.editorProps && this.editorProps[CommonRulesFields.Warning_message]) {
      return this.editorProps[CommonRulesFields.Warning_message]?.placeholders
    }

    return this.FILTERS_PLACEHOLDERS
  }

  get banMessageEditorProps():any {
    if (this.editorProps && this.editorProps[CommonRulesFields.Ban_message]) {
      return this.editorProps[CommonRulesFields.Ban_message]?.placeholders
    }

    return this.FILTERS_PLACEHOLDERS
  }

  options(options: string): Array<SelectOption> {
    return (chatConfig as any).computed[options]
  }

  get showNoticeBeforeBanSetting(): boolean {
    return this.getModel[CommonRulesFields.Notice_before_ban_count] > 0
  }

  get showBanNoticeSetting(): boolean {
    return this.getModel[CommonRulesFields.Ban_notice]
  }
}
