var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rank-select"},[(_vm.getTagsByFieldKey('extended_reputation'))?_c('config-field',{attrs:{"title":_vm.$t('rank_select_title'),"mini-help-message":_vm.$t('rank_select_mini_help_message'),"tags":_vm.getTagsByFieldKey('extended_reputation')}},[_c('div',{staticClass:"relative mt-2",staticStyle:{"height":"80px"}},[_c('not-available-options-overlay')],1)]):[_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.field,
          'key': 'resolve_from_rank',
          'prefix': 'rank_',
          'options': _vm.ranks,
          'clearable': false,
          'hasAnchor': true
        }
      }}}),(!_vm.isXpSystemIsEnabled)?_c('a-alert',{attrs:{"type":"info","show-icon":"","message":_vm.$t('rank_select_rank_system_is_not_enabled')}},[_c('switch-input',{attrs:{"slot":"description","setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.$store.state.chatState.chat.config,
            'key': 'user_ranks_by_xp',
            'hasAnchor': true
          }
        }},slot:"description"})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }