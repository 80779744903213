




















































import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import { InputSetups } from '@/mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { TagData } from 'piramis-base-components/src/components/Tags/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { get } from 'lodash'
import Vue from 'vue'

type UserRank = {
  title: string,
  reputation: number
  xp: number
}

@Component({
  data() {
    return {
      getTariffTranslationKey,
      get
    }
  },
  components: {
    ConfigField,
    NotAvailableOptionsOverlay
  }
})
export default class NewRankSelect extends Mixins<UseFields, InputSetups, TariffsTagsHelper>(UseFields, InputSetups, TariffsTagsHelper) {

  @Prop() field!: any

  get isXpSystemIsEnabled(): boolean {
    return this.$store.state.chatState.configCopy.user_ranks_by_reputation || this.$store.state.chatState.configCopy.user_ranks_by_xp
  }

  getModel(modelPath: string): any {
    return get(this, modelPath)
  }

  ranks(): Array<SelectOption> {
    return (this.$store.state.chatState.configCopy.user_ranks as Array<UserRank>)
      .map((rank, rankIndex: number) => ({
        label: rankIndex === 0 ? this.$t('rank_select_list_nobody') : rank.title,
        value: rankIndex
      }))
  }
}
