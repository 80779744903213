import { CommonRulesFields, ExtraSettingsMenuItems, VirtualNewFields } from "@/views/chat/filters/FilterTypes";

import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component
export default class FilterViewMixin extends Vue {
  @Prop() advancedFields !:Array<string> | undefined

  currentView: ExtraSettingsMenuItems | null = null

  @Emit('viewUpdated')
  setCurrentView(view: ExtraSettingsMenuItems | null): ExtraSettingsMenuItems | null {
    return view
  }

  handleCurrentView(view: ExtraSettingsMenuItems): void {
    this.currentView = view
    this.setCurrentView(view)
  }

  handleNavBack(value: null): void {
    this.currentView = value
    this.setCurrentView(value)
  }

  created():void {
      if (this.$route.hash) {
        const cuttedHash = this.$route.hash.slice(1)

        const commonRulesFields = Object.values(CommonRulesFields) as Array<string>
        const virtualNewFields = Object.values(VirtualNewFields) as Array<string>

        if (commonRulesFields.includes(cuttedHash)) {
          this.handleCurrentView(ExtraSettingsMenuItems.CommonRules)
        }

        if (virtualNewFields.includes(cuttedHash)) {
          this.handleCurrentView(ExtraSettingsMenuItems.VirtualNew)
        }

        if (this.advancedFields && this.advancedFields.includes(cuttedHash)) {
          this.handleCurrentView(ExtraSettingsMenuItems.AdvancedSettings)
        }
      }
  }
}
