var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"from-to"},[_c('highlight-anchor',{staticClass:"my-2 py-1",attrs:{"anchor-key":"filter_from_to","has-anchor-icon":true}},[_c('config-field',{staticClass:"mx-1",attrs:{"title":_vm.$t('filter_from_to')}},[_c('div',{staticClass:"flex flex-col xl:flex-row xl:items-end"},[_c('time-picker-input',{staticClass:"from-to__picker w-full",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': this,
              'key': 'filterFromTargetObject',
              'time24hr': true,
              'clearable': _vm.windowWidth <= 1200
            }
          }}}),_c('time-picker-input',{staticClass:"from-to__picker w-full",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': this,
              'key': 'filterToTargetObject',
              'time24hr': true,
              'clearable': _vm.windowWidth <= 1200
            }
          }}}),_c('transition',{attrs:{"name":"fade"}},[((_vm.filterFromTargetObject || _vm.filterToTargetObject) && _vm.windowWidth >= 1200)?_c('a-button',{staticClass:"ml-3 mt-5 flex-1 sm:flex-none",attrs:{"icon":"close","type":"danger"},on:{"click":_vm.resetFromTo}}):_vm._e()],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }