import i18n from "@/i18n/i18n"
import store from "@/store/store";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import { Placeholder } from 'piramis-base-components/src/components/Editor/components/Toolbar/types'

export function getTariff(key: string): Array<TagData> | [] {
  if ((store.state.chatState.chat as any).limits && !(store.state.chatState.chat as any).limits.options.includes(key)) {
    return [ {
      'color': "rgb(241,154,64)",
      'text': "Advanced"
    } ]
  }
  return []
}

export default function getUsers(arg: string): Array<Placeholder> {
  return [
    {
      label: i18n.t('message_editor_placeholders_user_username').toString(),
      value: `%${ arg }_username%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_loginlink').toString(),
      value: `%${ arg }_loginlink%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_id').toString(),
      value: `%${ arg }_user_id%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_action_points').toString(),
      value: `%${ arg }_action_points%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_xp_state').toString(),
      value: `%${ arg }_xp_state%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_user_days_in_chat').toString(),
      value: `%${ arg }_days_in_chat%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_user_days_from_first_message').toString(),
      value: `%${ arg }_days_from_first_message%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_parent_user').toString(),
      value: `%${ arg }_parent%`
    },
    {
      label: i18n.t('message_editor_placeholders_referral_url').toString(),
      value: `%${ arg }_referral_url%`
    },
      // РЕПУТАЦИЯ
    {
      label: i18n.t('message_editor_placeholders_user_reputation').toString(),
      value: `%${ arg }_reputation%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_anti_reputation').toString(),
      value: `%${ arg }_anti_reputation%`,
    },
    {
      label: i18n.t('message_editor_placeholders_user_reputation_state').toString(),
      value: `%${ arg }_reputation_state%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
      // РАНГ
    {
      label: i18n.t('message_editor_placeholders_user_xp_next_rank').toString(),
      value: `%${ arg }_xp_next_rank%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_user_rank').toString(),
      value: `%${ arg }_rank%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_user_rank_title').toString(),
      value: `%${ arg }_rank_title%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_user_reputation_next_rank').toString(),
      value: `%${ arg }_reputation_next_rank%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
      // РЕЙТИНГ
    {
      label: i18n.t('message_editor_placeholders_user_rating').toString(),
      value: `%${ arg }_rating%`,
    },
    {
      label: i18n.t('message_editor_placeholders_real_rating').toString(),
      value: `%${ arg }_real_rating%`
    },
  ]
}

export const getUserPointsPlaceholders = (arg: string): Array<Placeholder> => {
  return [
    {
      label: i18n.t('message_editor_placeholders_assigned_xp').toString(),
      value: `%${ arg }_assigned_xp%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_assigned_rep').toString(),
      value: `%${ arg }_assigned_rep%`,
      tags: [ ...getTariff('extra_placeholders') ]
    },
    {
      label: i18n.t('message_editor_placeholders_assigned_ap').toString(),
      value: `%${ arg }_ assigned_ap%`,
      tags: [ ...getTariff('extra_placeholders') ]
    }
  ]
}
