import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import getUsers, { getTariff, getUserPointsPlaceholders } from '@/includes/placeholders/getUsers'
import {
  PlaceholdersStack,
  PlaceholdersType
} from 'piramis-base-components/src/components/Editor/components/Toolbar/types'

@Component
export default class Placeholders extends Vue {

  get BASE_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('main_placeholders_title').toString(),
          sections: [ {
            buttonTitle: this.$t('message_editor_placeholders_message_info').toString(),
            placeholders: [
              {
                label: this.$t('message_editor_placeholders_message_info_text').toString(),
                value: '%message%'
              },
              {
                label: this.$t('message_editor_placeholders_message_info_reply_text').toString(),
                value: '%reply_message%'
              },
              {
                label: this.$t('message_editor_placeholders_message_info_id').toString(),
                value: '%message_id%'
              },
              {
                label: this.$t('message_editor_placeholders_message_info_reply_id').toString(),
                value: '%reply_message_id%'
              },
              {
                label: this.$t('message_editor_placeholders_message_info_link').toString(),
                value: '%message_link%'
              },
              {
                label: this.$t('message_editor_placeholders_message_info_reply_link').toString(),
                value: '%reply_message_link%'
              }
            ]
          },
            {
              buttonTitle: this.$t('message_editor_placeholders_chat_info').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_chat_info_chat_id').toString(),
                  value: '%chat_id%'
                },
                {
                  label: this.$t('message_editor_placeholders_chat_info_chat_title').toString(),
                  value: '%chat_title%'
                },
                {
                  label: this.$t('message_editor_placeholders_chat_info_chat_username').toString(),
                  value: '%chat_login%'
                },
                {
                  label: this.$t('message_editor_placeholders_chat_info_chat_reputation_url').toString(),
                  value: '%reputation_url%'
                },
                {
                  label: this.$t('message_editor_placeholders_referrals_url').toString(),
                  value: '%referrals_url%'
                },
                {
                  label: this.$t('message_editor_placeholders_referrals').toString(),
                  value: '%referrals%'
                }
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_rand_users').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_rand_user').toString(),
                  value: '%rnd_user_username(1-31)%',
                  tags: [ ...getTariff('extra_placeholders') ]
                },
                {
                  label: this.$t('message_editor_placeholders_rand_active_user').toString(),
                  value: '%rnd_active_user_username(1-31)%',
                  tags: [ ...getTariff('extra_placeholders') ]
                }
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_message_count_info').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_message_count_info_prev_day').toString(),
                  value: '%msg_prev_day_1%'
                },
                {
                  label: this.$t('message_editor_placeholders_message_count_info_prev_days').toString(),
                  value: '%msg_prev_days_1%'
                }
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_any_fields').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_timestamp').toString(),
                  value: '%timestamp%'
                },
                {
                  label: this.$t('message_editor_placeholders_timestamp_ms').toString(),
                  value: '%timestamp_ms%'
                },
                {
                  label: this.$t('message_editor_placeholders_rand').toString(),
                  value: '%rand%'
                },
                {
                  label: this.$t('message_editor_placeholders_rand_10').toString(),
                  value: '%rand10%'
                },
                {
                  label: this.$t('message_editor_placeholders_rand_100').toString(),
                  value: '%rand100%'
                },
                {
                  label: this.$t('message_editor_placeholders_rand_1000').toString(),
                  value: '%rand1000%'
                },
                {
                  label: this.$t('message_editor_placeholders_guid').toString(),
                  value: '%guid%'
                }
              ]
            } ]
        } ]
    }
  }

  get FACE_CONTROL_PLACEHOLDERS():PlaceholdersStack {
    return {
      type:PlaceholdersType.Stack,
      placeholders: [ {
        sectionTitle: this.$t('special_placeholders_title').toString(),
        sections: [ {
          buttonTitle: this.$t('message_editor_placeholders_face_control_target').toString(),
          placeholders: [ ...getUsers('target') ]
        },
          {
            buttonTitle: this.$t('message_editor_placeholders_face_control_warns').toString(),
            placeholders: [
              {
                label: this.$t('message_editor_placeholders_face_control_total_warns_count').toString(),
                value: '%total_warning_count%'
              },
              {
                label: this.$t('message_editor_placeholders_face_control_warns_count').toString(),
                value: '%warning_count%',
                description: this.$t('message_editor_placeholders_face_control_warn_description').toString()
              },
              {
                label: this.$t('message_editor_placeholders_face_control_max_warns_count').toString(),
                value: '%max_warning_count%',
                description: 'Если вы хотите вывести текущее количество предупреждений и сколько всего возможно до наказания используйте комбинацию %warning_count%/%max_warning_count%'
              }
            ]
          }
        ],
      },
        ...this.BASE_PLACEHOLDERS.placeholders ]
    }
  }

  get FILTERS_PLACEHOLDERS():PlaceholdersStack {
    return {
      type:PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_filters_target').toString(),
              placeholders: [
                ...getUsers('target'),
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_bans').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_ban_level').toString(),
                  value: '%banlevel%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time').toString(),
                  value: '%bantime%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_sec').toString(),
                  value: '%bantime_sec%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_day').toString(),
                  value: '%bantime_time_str%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_str').toString(),
                  value: '%bantime_str%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_to_hr').toString(),
                  value: '%bantime_to_hr%'
                },
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_face_control_warns').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_face_control_total_warns_count').toString(),
                  value: '%total_warning_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_face_control_warns_count').toString(),
                  value: '%warning_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_face_control_max_warns_count').toString(),
                  value: '%max_warning_count%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders ]
    }
  }

  get FORM_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [ {
        sectionTitle: this.$t('special_placeholders_title').toString(),
        sections: [
          {
            buttonTitle: this.$t('message_editor_placeholders_forms').toString(),
            placeholders: [
              {
                label: this.$t('message_editor_placeholders_forms_title').toString(),
                value: '%form_name%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_description').toString(),
                value: '%form_description%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_open_count').toString(),
                value: '%form_open_count%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_responce_count').toString(),
                value: '%form_responce_count%',
              },
            ]
          }
        ]
      },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get GREETING_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_greeting_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_greeting_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_captcha').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_captcha_numbers').toString(),
                  value: '%captcha%'
                },
                {
                  label: this.$t('message_editor_placeholders_captcha_words').toString(),
                  value: '%captcha_txt%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders

      ]
    }
  }

  get MODERATION_COMMANDS_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_time_reason').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_ban_time_sec').toString(),
                  value: '%bantime_sec%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time').toString(),
                  value: '%bantime%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_day').toString(),
                  value: '%bantime_time_str%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_str').toString(),
                  value: '%bantime_str%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_hr').toString(),
                  value: '%bantime_hr%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_to_hr').toString(),
                  value: '%bantime_to_hr%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_reason').toString(),
                  value: '%banreason%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get RANK_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_user').toString(),
              placeholders:[
                ...getUsers('target'),
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_in_chat').toString(),
                  value: '%target_days_in_chat%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_from_first_message').toString(),
                  value: '%target_days_from_first_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_rank').toString(),
                  value: '%target_rank%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_rank_title').toString(),
                  value: '%target_rank_title%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_with_message').toString(),
                  value: '%target_days_with_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_messages_count').toString(),
                  value: '%target_msg_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp').toString(),
                  value: '%target_xp%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp_next_rank').toString(),
                  value: '%target_xp_next_rank%'
                }
              ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get REFERRAL_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_referral_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_referral_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get SPEC_REP_TRIG_DEF_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get SPEC_REP_TRIG_OVR_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections:[
              ...this.SPEC_REP_TRIG_DEF_PLACEHOLDERS.placeholders[0].sections,
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_overtake').toString(),
              placeholders: [ ...getUsers('overtake') ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get TRIGGERS_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_triggers_actor').toString(),
              placeholders: [ ...getUsers('actor'),...getUserPointsPlaceholders('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_triggers_target').toString(),
              placeholders: [ ...getUsers('target'),...getUserPointsPlaceholders('target') ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get WARN_SYSTEM_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [ {
            buttonTitle: this.$t('message_editor_placeholders_warn_system_actor').toString(),
            placeholders: [ ...getUsers('actor') ]
          },
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_target').toString(),
              placeholders: [ ...getUsers('target') ]
            } ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }

  get XP_PLACEHOLDERS():PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_xp_target').toString(),
              placeholders: [
                ...getUsers('target'),
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_in_chat').toString(),
                  value: '%target_days_in_chat%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_from_first_message').toString(),
                  value: '%target_days_from_first_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_rank').toString(),
                  value: '%target_rank%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_rank_title').toString(),
                  value: '%target_rank_title%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_with_message').toString(),
                  value: '%target_days_with_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_messages_count').toString(),
                  value: '%target_msg_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp').toString(),
                  value: '%target_xp%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp_next_rank').toString(),
                  value: '%target_xp_next_rank%'
                },
              ],
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_xp_count').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_xp_earned').toString(),
                  value: '%earned_xp%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }
}
