





































































































import { InputSetups } from '../../../../../mixins/input-setups'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import chatConfig from '@/mixins/chatConfig'
import FilterFieldsMixins from '@/views/chat/filters/include/FilterFieldsMixins'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { get } from 'lodash'

@Component({
  data() {
    return {
      VirtualNewFields
    }
  },
  mixins: [ chatConfig ]
})
export default class VirtualNewSettings extends Mixins<UseFields, InputSetups, FilterFieldsMixins>(UseFields, InputSetups, FilterFieldsMixins) {

  get getModel(): any {
    const rootModelPath = `${ this.storePath }.${ this.filterType }`
    return get(this, rootModelPath)
  }

  get showRemoveEdited(): boolean {
    return this.getModel['remove_edited']
  }

  get showFirstMessageRemove(): boolean {
    return this.getModel['first_message_remove']
  }

  options(options: string): Array<SelectOption> {
    return (chatConfig as any).computed[options]
  }

}
