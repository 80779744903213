var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"virtual-new-settings"},[(_vm.isVisible(_vm.VirtualNewFields.Remove_edited))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'remove_edited',
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.showRemoveEdited)?_c('nested-content',[(_vm.isVisible(_vm.VirtualNewFields.Remove_edited_timeout))?_c('number-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'remove_edited_timeout',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true
        }
      }}}):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.Remove_edited_msg))?_c('number-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'remove_edited_msg',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true
        }
      }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.First_message_remove))?_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'first_message_remove',
        'prefix': (_vm.filterType + "_"),
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.showFirstMessageRemove)?_c('nested-content',[(_vm.isVisible(_vm.VirtualNewFields.First_message_ban_type))?_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.getModel,
          'key': 'first_message_ban_type',
          'options': _vm.options('ban_type_4'),
          'clearable': false,
          'hasAnchor': true
        }
      }}}):_vm._e()],1):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.New_limit))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'new_limit',
        'prefix': (_vm.filterType + "_"),
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true
      }
    }}}):_vm._e(),(_vm.isVisible(_vm.VirtualNewFields.New_limit_msg_count))?_c('number-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.getModel,
        'key': 'new_limit_msg_count',
        'prefix': (_vm.filterType + "_"),
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true
      }
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }