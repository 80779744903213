import { Vue, Component } from 'vue-property-decorator'
import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import { TagData } from "piramis-base-components/src/components/Tags/types";

@Component
export default class TariffsTagsHelper extends Vue {
  getTagsByFieldKey(key: string): Array<TagData> | null {
    if (this.$store.state.chatState.chat && this.$store.state.chatState.chat.limits) {
      if (!this.$store.state.chatState.chat.limits.options.includes(key)) {
        return [ {
          'text': this.$t(getTariffTranslationKey((this as any).$getOptionTariff(key))) as string,
          'color': this.$store.state.themeConfig.getTariff(this.$t(getTariffTranslationKey((this as any).$getOptionTariff(key)))).color
        } ]
      }

      return null
    }

    return null
  }
}
